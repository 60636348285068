<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll-nav ref="nav" current="基本信息" v-if="detail">
        <cube-scroll-nav-panel key="基本信息" label="基本信息">
          <inputItem title="报销单号" :initialContent="detail.code" :disabled="true"></inputItem>
          <inputItem title="发票状态" :initialContent="detail.invoice" :disabled="disabled" name="invoice" type="select" :options="invoiceOption" :contentChanged="contentChanged"></inputItem>
          <inputItem title="业务归属" v-model="detail.owner" type="select" :options="ownerOption" :disabled="disabled"></inputItem>
          <inputItem title="业务部门" v-model="detail.dept" type="select" :options="deptOption" :disabled="disabled"></inputItem>
          <inputItem title="费用时段" :initialContent="formatDate(detail.s_time)+' ~ '+formatDate(detail.e_time)" :disabled="disabled" name="time" type="segment" :contentChanged="contentChanged"></inputItem>
          <inputItem title="打款对象" v-model="detail.payee" :disabled="disabled"></inputItem>
          <inputItem title="研发投入" v-model="detail.rd_in" :disabled="disabled" type="select" :options="rdinOption"></inputItem>
          <inputItem title="报销备注" v-model="detail.remark" :disabled="disabled"></inputItem>
          <inputItem title="报销金额" :initialContent="formatMoney(detail.price)" :disabled="true" ref="price"></inputItem>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="报销明细" label="报销明细">
          <div class="mobile-detail-list">
            <div class="list-item" v-for="(item, index) in items" :key="index">
              <div class="item-header">
                <div class="item-index">明细{{ index + 1 }}</div>
                <img class="item-delete" src="@/static/images/icon_delete.png" alt @click="deleteItem(index)" v-if="!disabled" />
              </div>
              <inputItem title="报销金额" :initialContent="String(item.price)" :disabled="disabled" @input="setTotal(item)" name="price" :index="String(index)" :contentChanged="itemChanged"></inputItem>
              <inputItem title="费用类型" :initialContent="item.b_category_name+' - '+item.s_category_name" :disabled="disabled" type="cascade" :cascadeData="categoryOption" name="category" :index="String(index)" :contentChanged="itemChanged"></inputItem>
              <inputItem title="交易日期" :initialContent="formatDate(item.date)" :disabled="disabled" type="date" name="date" :index="String(index)" :contentChanged="itemChanged"></inputItem>
              <inputItem title="发票号码" v-model="item.invoice_number" :disabled="disabled"></inputItem>
              <inputItem title="所属项目" :initialContent="item.project_name" :disabled="disabled" type="others" ref="project_name" :index="String(index)" :otherClicked="clickProject"></inputItem>
              <inputItem title="所属客户" :initialContent="item.customer_name" :disabled="disabled" type="others" ref="customer_name" :index="String(index)" :otherClicked="clickCustomer"></inputItem>
              <inputItem title="业务代表" :initialContent="item.user_name" :disabled="disabled" type="others" ref="user_name" :index="String(index)" :otherClicked="clickUser"></inputItem>
              <inputItem title="消费城市" v-model="item.city" :disabled="disabled"></inputItem>
              <inputItem title="参与人员" v-model="item.users" :disabled="disabled"></inputItem>
              <inputItem title="费用备注" v-model="item.content" :disabled="disabled"></inputItem>
              <inputItem title="行驶里程" :initialContent="String(item.mileage)" :disabled="disabled" v-if="item.s_category_name=='差旅油费'" name="mileage" :index="String(index)" :contentChanged="itemChanged"></inputItem>
            </div>
            <div class="item-add" v-if="!disabled" alt @click="addItem"> 添加明细 </div>
          </div>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="审批流程" label="审批流程" v-if="flows && flows.flowLogs && flows.flowLogs.length > 0">
          <div class="mobile-detail-step">
            <Steps :current="flows.step" :status="flows.status" direction="vertical">
              <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
            </Steps>
          </div>
        </cube-scroll-nav-panel>
      </cube-scroll-nav>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
    <optionDrawer ref="optionDrawerProject" v-if="showOptionsProject" :onBgClicked="onBgClickedProject" :onSearch="onSearchProject" :items="projects" @optionClicked="optionClickedProject"> </optionDrawer>
    <optionDrawer ref="optionDrawerCustomer" v-if="showOptionsCustomer" :onBgClicked="onBgClickedCustomer" :onSearch="onSearchCustomer" :items="customers" @optionClicked="optionClickedCustomer"> </optionDrawer>
    <optionDrawer ref="optionDrawerUser" v-if="showOptionsUser" :onBgClicked="onBgClickedUser" :onSearch="onSearchUser" :items="users" @optionClicked="optionClickedUser"> </optionDrawer>
  </div>
</template>

<script>
import { GetCost, SaveCost, AuditCost, CancelCost } from "@/api";
import { formatDate, formatMoney } from "@/utils/format.js";
import { loadDetailAction, saveAction, auditAction, cancelAction, loadDataOptionAction, loadDataChildOptionAction, loadProjectOptionAction, loadCustomerOptionAction, loadUserOptionAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      items: [],
      flows: [],
      buttons: [],
      categoryOption: [],
      ownerOption: [],
      deptOption: [],
      invoiceOption: [{ text: '有票', value: "普票" }, { text: '无票（替票）', value: "无票" }],
      rdinOption: [{ text: '否', value: "否" }, { text: '是', value: "是" }],
      customers: [],
      showOptionsCustomer: false,
      projects: [],
      showOptionsProject: false,
      users: [],
      showOptionsUser: false,
      disabled: false,
      itemIndex: -1,
    };
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
    addItem: function () {
      this.items.push({ content: "", b_category_name: "", s_category_name: "", price: 0, date: this.detail.s_time, city: "武汉", users: this.$store.state.user.realName });
    },
    deleteItem: function (index) {
      this.items.splice(index, 1);
      this.setTotal();
    },
    setTotal: function () {
      var tot = 0;
      this.items.forEach(function (item) {
        tot += Number(item.price);
      });
      this.$refs.price.setContent(formatMoney(tot));
    },
    contentChanged: function (name, value) {
      switch (name) {
        case "invoice":
          this.detail.invoice = value;
          break;
        case "time":
          this.detail.s_time = value.split(",")[0];
          this.detail.e_time = value.split(",")[1];
          break;
      }
    },
    itemChanged: function (name, index, value, content) {
      switch (name) {
        case "category":
          this.items[Number(index)].b_category_id = value.split(",")[0];
          this.items[Number(index)].s_category_id = value.split(",")[1];
          this.items[Number(index)].b_category_name = content.split("-")[0].trim();
          this.items[Number(index)].s_category_name = content.split("-")[1].trim();
          break;
        case "price":
          this.items[Number(index)].price = value;
          this.setTotal();
          break;
        case "date":
          this.items[Number(index)].date = value;
          break;
        case "mileage":
          this.items[Number(index)].mileage = value;
          break;
      }
    },
    onSearchProject: function (value) {
      loadProjectOptionAction(this, value, (data) => { this.projects = data; });
    },
    onBgClickedProject: function () {
      this.showOptionsProject = false;
    },
    optionClickedProject: function (item) {
      this.items[this.itemIndex].project_id = item.id;
      this.items[this.itemIndex].project_name = item.name;
      this.items[this.itemIndex].customer_id = item.customer_id;
      this.items[this.itemIndex].customer_name = item.customer_name;
      this.items[this.itemIndex].user_id = item.leader_id;
      this.items[this.itemIndex].user_name = item.leader_name;
      this.$refs.project_name[this.itemIndex].setContent(item.name);
      this.$refs.customer_name[this.itemIndex].setContent(item.customer_name);
      this.$refs.user_name[this.itemIndex].setContent(item.leader_name);
      this.showOptionsProject = false;
    },
    clickProject: function (index) {
      this.itemIndex = Number(index);
      this.showOptionsProject = true;
      this.$nextTick(() => { setTimeout(() => { this.$refs.optionDrawerProject.show(); }, 100); });
    },
    onSearchCustomer: function (value) {
      loadCustomerOptionAction(this, value, (data) => { this.customers = data; });
    },
    onBgClickedCustomer: function () {
      this.showOptionsCustomer = false;
    },
    optionClickedCustomer: function (item) {
      this.items[this.itemIndex].customer_id = item.id;
      this.items[this.itemIndex].customer_name = item.name;
      this.items[this.itemIndex].user_id = item.user_id;
      this.items[this.itemIndex].user_name = item.user_name;
      this.$refs.customer_name[this.itemIndex].setContent(item.name);
      this.$refs.user_name[this.itemIndex].setContent(item.user_name);
      this.showOptionsCustomer = false;
    },
    clickCustomer: function (index) {
      this.itemIndex = Number(index);
      this.showOptionsCustomer = true;
      this.$nextTick(() => { setTimeout(() => { this.$refs.optionDrawerCustomer.show(); }, 100); });
    },
    onSearchUser: function (value) {
      loadUserOptionAction(this, value, (data) => { this.users = data; });
    },
    onBgClickedUser: function () {
      this.showOptionsUser = false;
    },
    optionClickedUser: function (item) {
      this.items[this.itemIndex].user_id = item.id;
      this.items[this.itemIndex].user_name = item.name;
      this.$refs.user_name[this.itemIndex].setContent(item.name);
      this.showOptionsUser = false;
    },
    clickUser: function (index) {
      this.itemIndex = Number(index);
      this.showOptionsUser = true;
      this.$nextTick(() => { setTimeout(() => { this.$refs.optionDrawerUser.show(); }, 100); });
    },
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveCost(1);
          break;
        case "提交审核":
          this.saveCost(2);
          break;
        case "审核通过":
          this.auditCost(true, "同意");
          break;
        case "审核拒绝":
          this.auditCost(false, "");
          break;
        case "撤回":
          this.cancelCost();
          break;
      }
    },
    saveCost: function (state) {
      this.detail.state = state;
      saveAction(this, SaveCost, { model: this.detail, items: this.items }, () => { this.$router.go(-1); }, () => { this.detail.state = 1; });
    },
    auditCost: function (pass, memo) {
      auditAction(this, AuditCost, this.id, pass, memo);
    },
    cancelCost: function () {
      cancelAction(this, CancelCost, this.id);
    },
    dataRequest: function () {
      loadDetailAction(this, GetCost, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.items = data.list[0].items;
        this.flows = data.list[0].flows;
        this.buttons = data.list[0].buttons;
        this.disabled = !this.buttons || this.buttons.indexOf("保存草稿") < 0;
        this.$nextTick(() => { this.$refs.nav.refresh(); });
      });
    },
  },
  mounted() {
    loadDataOptionAction(this, "业务归属", (data) => { this.ownerOption = data; });
    loadDataOptionAction(this, "业务部门", (data) => { this.deptOption = data; });
    loadDataChildOptionAction(this, "费用类型", (data) => { this.categoryOption = data; });

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>